import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Localbase from 'localbase'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

pinia.use(({ options, store }) => {
  if (options.persist) {
    // Check if the store is already initialised
    const db = new Localbase(`db_${store.$id}`)
    db.config.debug = false
    store.$state.isInitialised = false

    store.initStore = async function () {
      if (store.$state.isInitialised) return // Skip if already initialised
      store.$state.isInitialised = true

      // Loop through all keys in the store's state
      const keys = Object.keys(store.$state).filter(key => key !== 'isInitialised')
      for (const key of keys) {
        let persistedData = null
        if (Array.isArray(store.$state[key])) {
          persistedData = await db.collection(key).get()
          if (persistedData) {
            console.log(persistedData.length + ' of ' + key + ' records found in deep storage')
            store.$state[key] = persistedData
          }
        } else {
          persistedData = await db.collection('config').doc(key).get()
          if (persistedData) {
            store.$state[key] = persistedData.value
          }
        }

        // if (typeof store.$state[key] ==='object') {
        //     console.log(store.$state[key].value)
        //     await db.collection('config').add(store.$state[key].value)
        // }
      }
    }

    // Automatically call `initStore` when the store is accessed
    store.initStore()

    store.setState = async function (key, value) {
      store.$state[key] = value
      this.setDBState(key, value)
    }

    store.setDBState = async function (key, value) {
      try {
        if (Array.isArray(store.$state[key])) {
          await db.collection(key).delete()
          console.log(key)
          value.forEach(o => {
            db.collection(key).add(o)
          })
        } else {
          await db.collection('config').doc(key).set({
            value: value
          })
        }
      } catch (err) {
        console.error(err)
        console.error('Value trying to be set to ' + key)
        console.log(value)
      }
    }

    store.getState = async function (key) {
      try {
        if (Array.isArray(store.$state[key])) {

        } else {
          const value = await db.collection('config').doc(key).get()
          return value?.value
        }
      } catch (err) {
        console.error(err)
      }
    }

    store.flushState = async function () {
      try {
        await db.delete()
        const keys = Object.keys(store.$state).filter(key => key !== 'isInitialised')
        for (const key of keys) {
          if (Array.isArray(store.$state[key])) {
            store.$state[key] = []
          }
        }
      } catch (err) {
        console.log(err)
      }
    }

    // Attach the db instance to the store for later use if needed
    store.$db = db
  }
})

export default pinia
