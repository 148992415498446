<template>
  <div></div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useProfileStore } from '@/stores/profile'
const profile = useProfileStore()

let loop = null

onMounted(() => {
  clearInterval(loop)
  loop = setInterval(() => {
    if (profile.appOnline) {
      profile.authenticate().then(() => {
        profile.sync()
      })
    }
  }, 120000)
})

window.addEventListener('online', () => {
  profile.appOnline = true
})

window.addEventListener('offline', () => {
  profile.appOnline = false
})
</script>
