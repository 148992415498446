<template>
  <div>
    <badge class="cursor-pointer"
           @click="assign"
           :color="assignee ? part.meeting + '-400' : 'gray-300'"
           v-html="assignee ? assignee.full_name : partTag">
    </badge>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { useProfileStore } from '@/stores/profile'
import { find } from 'lodash'

const emit = defineEmits(['assign'])
const props = defineProps(
  {
    part: {
      required: false
    },
    hall: {
      required: false,
      default () {
        return 1
      }
    }
  }
)

const store = useCollectionsStore()
const profile = useProfileStore()

const assignment = computed(() => {
  return find(store.all('meetingAssignments'), a => a.meeting_part_id === props.part.id && a.hall === props.hall)
})

const assignee = computed(() => {
  return assignment.value ? find(store.all('users'), u => u.id === assignment.value.helper_id) : null
})

const partTag = computed(() => {
  if (props.part.meeting === 'living' && props.part.part === 'study') return 'Reader'
  if (props.part.meeting === 'watchtower' && props.part.part === 'discussion') return 'Reader'
  return 'Householder'
})

const assign = () => {
  if (!profile.can('schedules.meetings.assign')) return
  emit('assign', assignment.value, props.hall, 'helper')
}

</script>
