<template>

  <view-content :back="{ name:'Qualifications' }">

    <template v-slot:header>
      Part: {{ qualification ? qualification.part : '' }}
    </template>

    <template v-slot:actions>
      <action :primary="true" @click="assignFormActive = true">
        Add Publisher
      </action>
    </template>

    <table class="table is-fullwidth align-middle">
      <tr v-for="(user, key) in qualifiedPubs" :key="'qp' + key">
        <td>
          <span class="last-name">{{ user.last_name }}, </span>
          <span class="first-name">{{ user.first_name }}</span>
          <span v-if="user.appointment === 'elder'"><i>(Elder)</i></span>
          <span v-if="user.appointment === 'servant'"><i>(Servant)</i></span>
        </td>
        <td class="tools t1">
          <button @click="promptRemovePub(user)" class="btn btn-danger btn-round">
            <fa-icon :icon="['fal', 'trash']" />
          </button>
        </td>
      </tr>
    </table>

    <portal to="modals">
      <MeetingPartQualificationEditForm
        :active="assignFormActive"
        :part="props.id"
        @close="assignFormActive = false"
      />

      <delete
        :submitting="submitting"
        :header="'Remove Publisher'"
        :active.sync="activePromptRemovePub"
        :message="'Are you sure you wish to remove '+user.first_name+' '+user.last_name+' from this list?'"
        @submit="confirmRemovePub()"
      ></delete>
    </portal>

  </view-content>

</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { useAPIStore } from '@/stores/api'
import { orderBy, findIndex } from 'lodash'
import MeetingPartQualificationEditForm from '../../forms/MeetingPartQualificationEditForm.vue'

const emit = defineEmits(['update:loading'])
const props = defineProps(['id'])

const store = useCollectionsStore()
const api = useAPIStore()

const qualification = ref({
  part: null
})

const user = ref({
  id: null,
  first_name: null,
  last_name: null
})
const submitting = ref(false)
const assignFormActive = ref(false)
const activePromptRemovePub = ref(false)

const qualifiedPubs = computed(() => {
  let arr = orderBy(store.all('users'), ['last_first'])
  arr = arr.filter(u => {
    if (findIndex(u.qualified_parts, (p) => { return p.id === parseInt(props.id) }) >= 0) {
      return u
    }
  })
  return arr
})

onMounted(() => {
  emit('update:loading', true)
  api.get(['get-part', props.id]).then(res => {
    qualification.value = res.data
    emit('update:loading', false)
  })
})

const promptRemovePub = (u) => {
  user.value = u
  activePromptRemovePub.value = true
}
const confirmRemovePub = () => {
  submitting.value = true
  api.delete(['users', user.value.id, 'part-qualifications', props.id])
    .then(res => {
      store.merge('users', res)
      submitting.value = false
      activePromptRemovePub.value = false
    })
}

</script>
