<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-content modal-top">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            {{ header }}
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <p class="text-base">{{ message }}</p>
          </div>
        </div>
        <footer class="card-footer bg-red-500 text-light" v-if="axiosError">
          <p v-if="axiosError.message === 'The given data was invalid.'">{{ $t('validation.message') }}</p>
          <p v-else>{{ axiosError.message }}</p>
        </footer>
        <footer class="card-footer flex justify-between">
          <action :default="true" @click="$emit('update:active',false)">{{ $t('actions.cancel') }}</action>
          <action :danger="true" @click="$emit('submit')">
            <fa-icon :icon="['fal', 'spinner-third']" spin v-if="submitting || submittingTuxedo || api.submitting" />
            <span v-if="!submitting && !submittingTuxedo && !api.submitting">{{ $t('actions.delete') }}</span>
          </action>
        </footer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { store } from '@/store'
import { useAPIStore } from '@/stores/api'

defineProps(['active', 'header', 'submitting', 'message'])

const api = useAPIStore()
const axiosError = computed(() => store.state.core.axiosError)
const submittingTuxedo = computed(() => store.state.tuxedo.submitting)

onMounted(() => {
  store.commit('axiosError', null)
})

</script>

<style>

.card-content {
  text-align: left;
  padding: 15px;
}

</style>
