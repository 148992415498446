import Vue from 'vue'

export const UPDATE_CARTS = (state, data) => {
  state.cartLocations = data
}

export const updateQueue = (state, payload) => {
  state.axiosQueue.push({
    url: payload.url,
    message: payload.message,
    icon: payload.icon
  })
}

export const recordResponseTime = (state, payload) => {
  if (!state.axiosTiming[payload.url]) {
    Vue.set(state.axiosTiming, payload.url, [])
  }
  Vue.set(state.axiosTiming[payload.url], state.axiosTiming[payload.url].length, payload.seconds)
  if (state.axiosTiming[payload.url].length > 5) state.axiosTiming[payload.url].shift()
}

export const removeFromQueue = (state, payload) => {
  if (payload) {
    const key = state.axiosQueue.findIndex(q => q.url === payload.url)
    Vue.delete(state.axiosQueue, key)
  } else {
    const filterQueue = state.axiosQueue.filter(q => q.error && q.error.length > 0)
    Vue.set(state, 'axiosQueue', filterQueue)
  }
}
