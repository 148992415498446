<template>

  <view-content :back="{name:'Settings'}">

    <template v-slot:header>
      {{ $tc('resources.attendant_schedule', 0)}}
    </template>

    <template v-slot:actions>
      <action :primary="true" class="mr-2 mb-2" id="add-responsibility" v-if="permits['schedules.attendants.edit']" @click="addResponsibility">
        {{ $t('actions.action_resource', { action: $t('actions.add'), resource: $tc('resources.responsibility', 1) }) }}
      </action>
      <action v-if="false" :secondary="true" @click="loadTour">
        {{ $t('actions.action_resource', { action: $t('actions.take'), resource: $tc('resources.tour', 1) })}}
      </action>
    </template>

    <table class="table is-fullwidth align-middle">
      <sort-list lockAxis="y" v-model="responsibilities" :useDragHandle="true">
        <sort-item v-for="(res, key) in responsibilities" :index="key" :key="key">
          <td class="tools t1" v-if="permits['schedules.attendants.edit']" v-handle :id="key === 0 ? 'first-responsibility-order' : ''">
            <button class="btn btn-warning btn-round">
              <fa-icon :icon="['fal', 'sort']" />
            </button>
          </td>
          <td :id="key === 0 ? 'first-responsibility' : ''">
            <div>
              <span>{{ res.label }}</span><br>
              <small>Rotate {{ frequency[res.frequency] }}, {{ res.attendants.length }} Attendants</small><br>
              <small>Booked up to {{ date(res.current_date).format('l jS M, Y') }} </small>
            </div>
          </td>
          <td class="tools t2" v-if="permits['schedules.attendants.edit']">
            <button @click="editResponsibility(res)" class="btn btn-info btn-round mr-1">
              <fa-icon :icon="['fal', 'pencil']" />
            </button>
            <button @click="promptDeleteResponsibility(res)" class="btn btn-danger btn-round">
              <fa-icon :icon="['fal', 'trash']" />
            </button>
          </td>
        </sort-item>
      </sort-list>

    </table>

    <portal to="modals">
      <modal :submitting="submitting" :header="'Responsibility Form'" :active.sync="responsibilityFormActive" @submit="submitResponsibility" :validated="responsibilityValid">
        <template v-slot:default="{ error }">
          <tuxedo-input :name="'label'" :label="'Label'" :inputClass="'bg-gray-100'" v-model="responsibility.label" :maxlength="25" :help="'Label the responsibility, eg. Platform, Microphone Left, Microphone Right, etc.'" :error="error" />
          <tuxedo-radio :name="'frequency'" :label="'Rotation'" v-model="responsibility.frequency" :options="frequency" :merge="true" :help="'Set the frequency attendants will be rotated in this assignment. The Service App will do its best to ensure an attendant assignment doesn\'t conflict with dates away and other meeting assignments.'" :error="error" />
          <tuxedo-radio :name="'meeting'" :label="''" v-model="responsibility.meeting" :options="meeting" :merge="true" class="pb-1" :error="error" />
          <input-text :maxlength="500" :label="'Notes'" v-model="responsibility.notes" :help="'You can add some instructions here which will be included in any reminders sent to the attendant.'" :error="error" />
          <input-toggle class="mb-3" :label="'Show All Publishers'" v-model="showAll" :help="'Choose from all publishers in your congregation'" :error="error" />
          <input-select :inputClass="'bg-gray-100'" :label="'Approved Attendants'" :name="'attendants'"
                        v-model="responsibility.attendants"
                        :options="optionalPubs"
                        :multi="true"
                        :insistReturn="'id'"
                        :insistLabel="'last_first'"
                        :help="'Select the brothers approved to serve as attendants, the Service App handles ordering of assignments taking into consideration dates away and assigned meeting parts. On a computer \'control\' click to select multiple publishers.'"
                        :error="error">
            <template #label="{ option }">
              <div>
                <div class="font-semibold">{{ option.last_first }}</div>
                <small class="font-normal">{{ option.appointment }}</small>
              </div>
            </template>
          </input-select>
        </template>
      </modal>

      <delete
        :submitting="submitting"
        :header="'Delete Responsibility'"
        :active.sync="responsibilityDeleteActive"
        :message="'Are you sure you wish to delete this responsibility?'"
        @submit="confirmDeleteResponsibility()"
      ></delete>
    </portal>

  </view-content>

</template>

<script>
import { mapStores } from 'pinia'
import { get, post, put, destroy } from '@/mixins/api'
import { assign, merge, push, forget } from '@/mixins/storeMutations'
import { resetData, fillObject } from '@/mixins/helpers'
import { HandleDirective } from 'vue-slicksort'
import { mapState } from 'vuex'
import { Sec } from '@/mixins/sec'
import { useCollectionsStore } from '@/stores/collections'

export default {
  name: 'AttendantSchedule',
  directives: { handle: HandleDirective },
  mixins: [get, post, put, destroy, assign, merge, push, forget, resetData, fillObject],
  data () {
    return {
      showAll: false,
      responsibility: {
        id: null,
        label: null,
        frequency: null,
        meeting: 'both',
        notes: null,
        attendants: []
      },
      schedule: {
        label: null,
        notes: null
      },
      frequency: {
        meeting: 'Meeting',
        week: 'Weekly',
        fortnight: '2 Weekly',
        month: 'Monthly'
      },
      meeting: {
        weekend: 'Weekend Only',
        mid: 'Mid-Week Only',
        both: 'Both Meetings'
      },
      activeID: null,
      responsibilityFormActive: false,
      responsibilityDeleteActive: false,
      submitting: false,
      loading: true,
      error: false
    }
  },
  computed: {
    ...mapStores(useCollectionsStore),
    ...mapState({
      permits: state => state.core.permits,
      cong: state => state.settings.cong,
      tour: state => state.attendants.tour,
      res: state => state.attendants.responsibilities
    }),
    responsibilityValid () {
      return this.responsibility.label && this.responsibility.frequency
    },
    responsibilities: {
      get: function () {
        return this._.orderBy(this.$store.state.attendantResponsibilities, ['order'])
      },
      set: function (value) {
        const responsibilities = []
        this._.forEach(value, (responsibility, key) => {
          responsibility.order = key
          responsibilities.push(responsibility)
        })
        this.$store.dispatch('SUBMIT_RESPONSIBILITY_ORDER', { responsibilities: responsibilities })
      }
    },
    optionalPubs () {
      let users = this._.orderBy(this.collectionsStore.users, ['last_first'])
      users = users.filter(user => {
        if (parseInt(user.active) === 0) return false
        if (this.showAll) return user
        // Ideally brother
        if (parseInt(user.gender) === 2 || ['student', 'guest', 'inactive'].includes(user.appointment)) return false
        return user
      })
      return users
    }
  },
  created () {
    this.get('attendant-responsibilities').then(res => {
      this.assign('attendantResponsibilities', res)
    })
  },
  methods: {
    date (time) {
      return new Sec(time)
    },
    addResponsibility () {
      this.resetData('responsibility')
      this.responsibilityFormActive = true
      if (this.$tours['attendants-schedule-tour'].currentStep === 4) {
        this.$tours['attendants-schedule-tour'].currentStep++
      }
    },
    submitResponsibility () {
      if (this.responsibility.id) {
        this.put(['attendant-responsibilities', this.responsibility.id], this.responsibility).then(res => {
          this.merge('attendantResponsibilities', res.data.data)
          this.responsibilityFormActive = false
        })
      } else {
        this.post(['attendant-responsibilities'], this.responsibility).then(res => {
          this.push('attendantResponsibilities', res)
          this.responsibilityFormActive = false
          if (this.$tours['attendants-schedule-tour'].currentStep === 6) {
            this.$tours['attendants-schedule-tour'].currentStep++
          }
        })
      }
    },
    editResponsibility (res) {
      this.responsibilityFormActive = true
      this.resetData('responsibility')
      this.fillObject(this.responsibility, res, 0)
    },
    promptDeleteResponsibility (res) {
      this.activeID = res.id
      this.responsibilityDeleteActive = true
    },
    confirmDeleteResponsibility () {
      this.destroy(['attendant-responsibilities', this.activeID]).then(() => {
        this.forget('attendantResponsibilities', this.activeID)
        this.responsibilityDeleteActive = false
      })
    },
    loadTour () {
      this.$tours['attendants-schedule-tour'].start()
    }
  }
}
</script>
