<template>
  <div>
    <table class="bg-white w-100 mt-4">
      <tr>
        <td class="align-middle p-2"><strong>{{ $t('labels.attendance.label') }}:</strong> {{ attendance }}</td>
        <td class="tools t1 p-2">
          <button class="btn btn-info btn-round" @click="editAttendance">
            <fa-icon :icon="['fal', 'pencil']" />
          </button>
        </td>
      </tr>
    </table>
    <modal :header="$t('labels.attendance.label')" :active.sync="attendanceFormActive" @submit="submitAttendance">
      <input-text :label="$t('fields.attendance.label')" :type="'number'" v-model="form.attendance"></input-text>
    </modal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useAPIStore } from '@/stores/api'
import { useCollectionsStore } from '@/stores/collections'

const props = defineProps(['attendance', 'meeting'])

const api = useAPIStore()
const store = useCollectionsStore()

const form = ref({
  meeting_id: null,
  attendance: null
})
const attendanceFormActive = ref(false)

const editAttendance = () => {
  attendanceFormActive.value = true
  form.value.meeting_id = props.meeting.id
  form.value.attendance = props.attendance
}

const submitAttendance = () => {
  api.post(['meeting-attendance'], form.value)
    .then(res => {
      store.merge('meetingAttendance', res)
      attendanceFormActive.value = false
    })
}

</script>
