<template>

  <view-content :back="{ name:'Settings' }">

    <template v-slot:header>
      {{ labels.name }}
    </template>

    <template v-slot:actions>
      <action :primary="true" v-if="profile.can('settings.users.add')" @click="create">
        {{ labels.create }}
      </action>
    </template>

    <list-item v-for="r in store.all('users')" :key="'user' + r.id" :class="borderClass(r)">
      <template v-slot:header>
        <a href="" :id="r.id+'-renew'" class="d-none"></a>
        <div class="relative">
          <div :class="textColorClass(r)">{{ r.last_first }}</div>
          <div class="absolute right-0 top-0">
            <div class="px-3 bg-gray-200 py-1 text-gray-500 rounded-full shadow cursor-pointer" @click="userActions = r.id" :id="'userMenu' + r.id">
              <fa-icon :icon="['fas', 'ellipsis-v']" />
            </div>
          </div>
        </div>
      </template>
      <div>
        <span v-if="r.appointment === 'elder'">Elder</span>
        <span v-else-if="r.appointment === 'servant'">Servant</span>
        <span v-else-if="r.appointment === 'publisher'">Publisher</span>
        <span v-else-if="r.appointment === 'inactive'">Inactive</span>
        <span v-else-if="r.appointment === 'unbaptised'">Unbaptised Publisher</span>
        <span v-else-if="r.appointment === 'student'">Student</span>
        <span v-else-if="r.appointment === 'guest'">Guest</span>
        <span v-if="r.group_name"> | {{ r.group_name }}</span>
      </div>
      <div v-if="r.phone">
        <a :href="'tel:'+r.phone">tel: {{ r.phone }}</a>
      </div>
    </list-item>

    <secondary-menu v-if="userActions" @close="userActions = false" :target="'userMenu' + userActions">
      <ul class="text-lg font-sans">
        <sm-item class="px-4 text-teal-400" v-if="profile.can('settings.users.send_login')" @click="openUser(userActions)" :icon="['fal', 'user-edit']">
            <span>
              {{ $t('actions.action_resource', { action: $t('actions.view'), resource: $t('labels.publisher_details') }) }}
            </span>
        </sm-item>
        <sm-item class="px-4" v-if="profile.can('settings.users.send_login')" @click="promptRenewUser(userActions)" :icon="['fal', 'paper-plane']">
            <span>
              {{ $t('actions.action_resource', { action: $t('actions.send'), resource: $tc('resources.invite', 1) }) }}
            </span>
        </sm-item>
        <sm-item class="px-4" v-if="profile.can('settings.users.edit')" @click="update(userActions)" :icon="['fal', 'pencil']">
            <span>
              {{ $t('actions.action_resource', { action: $t('actions.edit'), resource: $tc('resources.user', 1) }) }}
            </span>
        </sm-item>
        <sm-item v-if="profile.can('settings.users.delete')" @click="promptDestroy(userActions)" :icon="['fal', 'trash']" class="px-4 text-red-700 border-t mt-2">
          {{ $t('actions.action_resource', { action: $t('actions.delete'), resource: $tc('resources.user', 1) }) }}
        </sm-item>
      </ul>
    </secondary-menu>

    <portal to="modals">

      <PublisherForm
        :user="user"
        :active="publisherEditFormActive"
        @close="publisherEditFormActive = false"
      />

      <PublisherInviteForm
        :user="user"
        :active="publisherInviteFormActive"
        @close="publisherInviteFormActive = false"
      />

      <delete
        :header="labels.delete"
        :active.sync="destroyUsers"
        :message="messages.delete"
        @submit="destruction"
      ></delete>
    </portal>

  </view-content>

</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { useProfileStore } from '@/stores/profile'
import { useRouter } from 'vue-router/composables'
import { get, destroy } from '@/composables/serverInterface'
import { assign } from '@/composables/dataInterface'
import { useLabels, useMessages } from '@/composables/labels'
import PublisherForm from '../forms/PublisherForm.vue'
import PublisherInviteForm from '../forms/PublisherInviteForm.vue'

const store = useCollectionsStore()
const profile = useProfileStore()
const router = useRouter()
const labels = useLabels('users')
const messages = useMessages('users')
const activeID = ref(null)
const userActions = ref(false)
const destroyUsers = ref(false)
const user = ref(null)
const publisherEditFormActive = ref(false)
const publisherInviteFormActive = ref(false)

onMounted(() => {
  window.scrollTo(0, 0)
  get('users').then(res => {
    assign('users', res)
  })
  get('roles').then(res => {
    assign('roles', res)
  })
  get('groups').then(res => {
    assign('groups', res)
  })
})

const borderClass = (user) => {
  if (user.appointment === 'elder') return 'border-elder'
  else if (user.appointment === 'servant') return 'border-servant'
  else return 'border-publisher-600'
}
const textColorClass = (user) => {
  if (user.appointment === 'elder') return 'text-elder'
  else if (user.appointment === 'servant') return 'text-servant'
  else return 'text-publisher-600'
}
const create = () => {
  user.value = null
  publisherEditFormActive.value = true
}
const update = (id) => {
  user.value = store.find('users', id)
  publisherEditFormActive.value = true
  userActions.value = false
}
const promptDestroy = (id) => {
  userActions.value = false
  activeID.value = id
  destroyUsers.value = true
}
const promptRenewUser = (id) => {
  user.value = store.find('users', id)
  publisherInviteFormActive.value = true
  userActions.value = false
}

const openUser = (id) => {
  router.push('/settings/users/' + id)
}
const destruction = () => {
  destroy(['users', activeID.value]).then(() => {
    store.forget('users', activeID.value)
    destroyUsers.value = false
  })
}

</script>
