<template>

  <div v-if="assignment">
    <section class="assignment" :class="assignment.base_type" v-if="
      assignment.extent === 'everyone' ||
      (assignment.extent === 'elders' && profile.appointment === 'elder') ||
      (assignment.extent === 'appointed' && (profile.appointment === 'servant' || profile.appointment === 'elder')) ||
      (assignment.extent === 'pioneers_elders' && (profile.appointment === 'elder' || profile.pioneer)) ||
      (assignment.extent === 'pioneers' && profile.pioneer) ||
      !assignment.extent
      ">

      <card v-if="meeting === 'ministry'" :start="start" :end="end" :date="date"
            :color="assignment.base_type" :assignee="assignee" :assistant="assistant" class="mb-5"
      >
        <template v-slot:header>
          <span>{{ $t('labels.appointments.'+assignment.base_type) }}</span>
        </template>
        <template v-slot:content>
          <span v-if="assignment.base_type === 'meeting'" v-html="talkTitle"></span>
        </template>
      </card>

      <card v-else :start="start" :end="end" :date="date" :time="time" :location="location" :online="online"
            :color="assignment.base_type" class="mb-5"
            :actionSlot="assignment.base_type === 'group'"
      >
        <template v-slot:header>
          <span v-if="assignment.base_type === 'custom'">{{ assignment.label }}</span>
          <span v-else>{{ $t('labels.appointments.'+assignment.base_type) }}</span>
        </template>
        <template v-slot:content>
          <span v-if="assignment.theme">{{ $t('labels.theme', { theme: assignment.theme }) }}<br></span>
          <span v-if="assignment.base_type === 'assembly'">{{ $t('labels.theme', { theme: assignment.label }) }}</span>
          <span v-if="assignment.base_type === 'attendant'">{{ $t('labels.attendant_responsibility', { responsibility: assignment.responsibility }) }}</span>
          <span v-if="assignment.base_type === 'meeting'" v-html="talkTitle"></span>
          <span v-if="assignment.base_type === 'group'" v-html="assignment.label"></span>
          <span v-if="meeting === 'ministry'">Here</span>
          <span v-if="assignment.speaker">
            <span v-html="assignment.speaker"></span><br>
          </span>
          <span v-if="assignment.base_type === 'talk'">
            <span v-html="assignment.notes"></span><br>
            <span>{{ $t('labels.cong_name', { congregation: assignment.congregation }) }}</span>
          </span>
          <span v-if="assignment.base_type === 'convention'">
            <span>{{ assignment.label }}</span>
            </span>
          <span v-if="assignment.base_type === 'annual_pioneer'">
            <span>{{ $t('labels.theme', { theme: assignment.label }) }}</span>
          </span>

        </template>
        <template v-slot:actions>
          <div v-if="assignment.base_type === 'group'">
            <action :primary="true" :small="true" @click="promptChangeAssignment(assignment.id)">{{ $t('actions.change') }}</action>
          </div>
        </template>
      </card>

      <modal :submitting="submitting" :header="'Request Assignment Change'" :active.sync="changeAssignmentFormActive" @submit="submitRequestChange()" :validated="true">
        <tuxedo-select
          :label="'New Overseer'"
          name="overseer"
          :options="overseers"
          v-model="specialAssignment.overseer"
          insistLabel="last_first"
          inputClass="bg-gray-100"
          insistReturn="id"
        />
      </modal>

    </section>
  </div>

</template>

<script setup>
import { computed, ref } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { store } from '@/store'
import { filter, find, orderBy } from 'lodash'

const props = defineProps(['assignment'])

const newStore = useCollectionsStore()

const specialAssignment = ref({
  id: null,
  overseer: null
})
const changeAssignmentFormActive = ref(false)
const submitting = ref(false)

const profile = computed(() => store.state.profile)

const start = computed(() => { return new Date(props.assignment.base_date) })
const end = computed(() => { return new Date(props.assignment.end_date) })
const date = computed(() => {
  const arr = ['co', 'convention']
  if (arr.includes(props.assignment.base_type)) return 'range'
  return true
})
const time = computed(() => {
  const incl = ['assembly', 'annual_pioneer', 'kmse']
  const excl = ['co', 'convention']
  if (incl.includes(props.assignment.base_type)) return 'range'
  if (excl.includes(props.assignment.base_type)) return false
  return true
})
const meeting = computed(() => {
  if (props.assignment.part) {
    const arr = props.assignment.part.split('.')
    return arr[0]
  }
  return null
})
const location = computed(() => {
  if (props.assignment.online) return false
  return props.assignment.location
})
const online = computed(() => {
  if (props.assignment.online) return props.assignment.location
  return false
})
const assignee = computed(() => {
  if (props.assignment.user_id) return find(newStore.all('users'), u => parseInt(u.id) === parseInt(props.assignment.user_id))
  return false
})
const assistant = computed(() => {
  if (props.assignment.helper_id) return find(newStore.all('users'), u => parseInt(u.id) === parseInt(props.assignment.helper_id))
  return false
})
const overseers = computed(() => {
  const pubs = filter(newStore.all('users'), pub => {
    if (pub.active && (['servant', 'elder'].includes(pub.appointment) || pub.role === 12)) return pub
  })
  return orderBy(pubs, ['last_name'])
})
const talkTitle = computed(() => {
  const parts = props.assignment.part.split('.')
  let title = props.assignment.title
  if (['first', 'second', 'third', 'fourth'].includes(parts[1])) {
    title += ' <i>(' + parts[1] + ')</i>'
  }
  const arr = ['watchtower.discussion', 'living.study', 'ministry.first', 'ministry.second', 'ministry.third', 'ministry.study']
  if (arr.includes(props.assignment.part) && props.assignment.helper_id === profile.value.id) {
    return props.assignment.reader
  }
  return title
})

const promptChangeAssignment = (id) => {
  specialAssignment.value.id = id
  changeAssignmentFormActive.value = true
}
const submitRequestChange = () => {
  submitting.value = true
  store.dispatch('REQUEST_CHANGE_ASSIGNMENT', { assignment: specialAssignment.value }).then(() => {
    submitting.value = false
    changeAssignmentFormActive.value = false
  })
}

</script>
