<template>
  <div>
    <modal :header="$t('labels.congregation.ptc')" :active="active" @submit="submit" @close="emit('close')">
      <template v-slot:default>
        <tuxedo-select
          :label="$t('fields.ptc.label')"
          :name="'ptc'"
          v-model="resource.ptc"
          :options="publishersWithEmail"
          :insistLabel="'last_first'"
          :insistReturn="'id'"
          :inputClass="'bg-gray-100'"
        >
          <template #label="{ option }">
            {{ option.last_first }}
          </template>
          <template #content="{ option }">
            {{ option.email }}
          </template>
        </tuxedo-select>
      </template>
    </modal>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { post } from '@/composables/serverInterface.js'
import { filter, orderBy } from 'lodash'

const props = defineProps(['congregation', 'ptc', 'active'])
const emit = defineEmits(['close'])

const store = useCollectionsStore()

const initialResource = {
  ptc: null
}
const resource = ref({ ...initialResource })

const publishersWithEmail = computed(() => {
  const filtered = filter(store.all('users'), u => u.email && u.active)
  return orderBy(filtered, ['last_first'])
})

watch(() => props.active, async (active) => {
  if (active === true) {
    if (props.ptc) {
      resource.value.ptc = props.ptc.id
    }
  }
})

const submit = () => {
  resource.value.congregation = props.congregation.id
  post(['congregation', props.congregation.id, 'ptc'], resource.value).then(res => {
    emit('saveResponse', res)
  })
}

</script>
