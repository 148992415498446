<template>

  <div>
    <card :icon="['fal', 'user-tie']" :color="part.meeting" class="mb-3" :time="chairman ? '' : 'range'"
          :start="part.startTime" :end="endTime" :minutes="chairman ? '' : part.time"
          :congregation="partCongregation"
          :actionSlot="edit"
          :reference="part.references || part.notes" :loading="timeThroughPart">
      <template v-slot:header><span v-html="partTitle"></span></template>
      <template v-slot:badges>
        <div class="flex align-items-center">
          <div style="width: 85px" v-if="partCanHaveSecondSchool && halls > 1">Main Class</div>
          <assignee v-if="partCanBeAssigned" :part="part" :hall="hall" @assign="assign" />
          <helper v-if="partRequiresHelper" :part="part" :hall="hall" @assign="assign" />
        </div>
        <div class="flex mt-1" v-if="partCanHaveSecondSchool && halls > 1">
          <div style="width: 85px">Aux Class 1</div>
          <assignee v-if="partCanBeAssigned" :part="part" :hall="2" @assign="assign" />
          <helper v-if="partRequiresHelper" :part="part" :hall="2" @assign="assign" />
        </div>
        <div class="flex mt-1" v-if="partCanHaveSecondSchool && halls > 2">
          <div style="width: 85px">Aux Class 2</div>
          <assignee v-if="partCanBeAssigned" :part="part" :hall="3" @assign="assign" />
          <helper v-if="partRequiresHelper" :part="part" :hall="3" @assign="assign" />
        </div>
      </template>
      <template v-slot:actions>
        <div v-if="edit">
          <action :primary="true" :small="true" @click="popupMenu = !popupMenu" :id="'editPart' + part.id">{{ $t('actions.edit') }}</action>
        </div>
      </template>

    </card>

    <tuxedo-secondary-menu v-if="popupMenu" @close="popupMenu = false" :target="'editPart' + part.id">
      <ul class="text-lg font-sans p-2 text-left">
        <sm-item class="justify-content-center" @click="insertPart(part)">
          <span>
            {{ $t('actions.action_resource', { action: $t('actions.insert'), resource: $tc('resources.meeting_part', 1) }) }}
          </span>
        </sm-item>
        <sm-item class="justify-content-center" @click="editPart">
          <span>
            {{ $t('actions.action_resource', { action: $t('actions.edit'), resource: $tc('resources.meeting_part', 1) }) }}
          </span>
        </sm-item>
        <sm-item @click="destroyPart" :icon="['fal', 'trash']" class="justify-content-center text-red-700 border-t mt-2">
          {{ labels.delete }}
        </sm-item>
      </ul>
    </tuxedo-secondary-menu>

    <modal v-if="edit" :submitting="submitting" :header="'Edit Meeting Part'" :active.sync="editPartActive" @submit="submitPart">
      <template v-slot:default="{ error }">
        <tuxedo-input :inputClass="'bg-gray-100'" :label="$t('labels.meetings.title')" :name="'title'" v-model="resource.title" :error="error" />
        <tuxedo-input :inputClass="'bg-gray-100'" :label="$t('labels.minutes.label')" :help="$t('labels.minutes.tip')" :type="'number'" :name="'time'" v-model="resource.time" :error="error" />
        <tuxedo-input :inputClass="'bg-gray-100'" :label="$t('labels.references.label')" :help="$t('labels.references.tip')" :name="'references'" v-model="resource.references" :error="error" />
        <tuxedo-select :inputClass="'bg-gray-100'" :label="$t('labels.part_type.label')" :name="'user_meeting_part_id'"
                       v-model="resource.user_meeting_part_id" :options="parts" :insistReturn="'id'" :insistLabel="'part'"
                       :help="$t('labels.part_type.tip')"
                       :insistDisplay="resource.user_meeting_part_id ? $t('labels.meetings.' + find(parts, 'id', resource.user_meeting_part_id, 'part')) : ''"
                       :error="error"
        >
          <template v-slot:label="{ option }">
            {{ $t('labels.meetings.' + option.part) }}
          </template>
        </tuxedo-select>
        <tuxedo-select :inputClass="'bg-gray-100'" :label="$t('labels.helper.label')" :name="'helper_type'"
                       v-model="resource.helper_type" :help="$t('labels.helper.tip')" :options="helperTypes"
                       :insistReturn="'type'"
                       :insistDisplay="resource.helper_type ? $t('labels.meetings.' + resource.helper_type) : ''"
                       :error="error"
        >
          <template v-slot:label="{ option }">
            {{ $t('labels.meetings.' + option.type) }}
          </template>
        </tuxedo-select>
        <tuxedo-select v-if="resource.helper_type !== 'none'" :inputClass="'bg-gray-100'" :name="'helper_meeting_part_id'"
                       :label="$t('labels.helper_type.label')" v-model="resource.helper_meeting_part_id" :options="parts"
                       :insistReturn="'id'" :insistLabel="'part'" :help="$t('labels.helper_type.tip')"
                       :insistDisplay="resource.helper_meeting_part_id ? $t('labels.meetings.' + find(parts, 'id', resource.helper_meeting_part_id, 'part')) : ''"
                       :error="error"
        >
          <template v-slot:label="{ option }">
            {{ $t('labels.meetings.' + option.part) }}
          </template>
        </tuxedo-select>
      </template>
    </modal>

    <delete
      :submitting="submitting"
      :header="labels.delete"
      :active.sync="deletePartActive"
      :message="$t('messages.delete_resource', { resource: $tc('resources.meeting_part', 1) })"
      @submit="destruction"
    ></delete>

  </div>

</template>

<script setup>
import { computed, ref } from 'vue'
import { useAPIStore } from '@/stores/api'
import { useCollectionsStore } from '@/stores/collections'
import { useProfileStore } from '@/stores/profile'
import { store } from '@/store'
import { i18n } from '@/i18n'
import { fillObject } from '@/helpers'

const emit = defineEmits(['assign'])
const props = defineProps(
  {
    part: {
      required: false
    },
    chairman: {
      required: false
    },
    hall: {
      required: false,
      default () {
        return 1
      }
    },
    edit: {
      required: false
    },
    halls: {
      required: false
    },
    currentTime: {
      required: false
    },
    defaultTag: {
      required: false
    }
  }
)

const api = useAPIStore()
const newStore = useCollectionsStore()
const profile = useProfileStore()

const initialResource = {
  id: null,
  title: null,
  time: null,
  references: null,
  meeting: null,
  order: null,
  user_meeting_part_id: null,
  helper_meeting_part_id: null,
  helper_type: 'none'
}

const resource = ref({ ...initialResource })

const helperTypes = ref([
  { type: 'none' },
  { type: 'householder' },
  { type: 'reader' },
  { type: 'student' }
])
const popupMenu = ref(false)
const editPartActive = ref(false)
const deletePartActive = ref(false)
const labels = ref({
  delete: i18n.t('actions.action_resource', { action: i18n.t('actions.delete'), resource: i18n.tc('resources.meeting_part', 1) })
})

const parts = computed(() => store.state.parts)
const submitting = ref(false)

const partCanBeAssigned = computed(() => {
  if (props.part.meeting === 'prelude' && props.part.part === 'comments' && !props.chairman) return false
  if (props.part.meeting === 'talk' && props.part.part === 'song' && !props.chairman) return false
  if (props.part.meeting === 'living' && props.part.part === 'mid-song') return false
  if (props.part.meeting === 'living' && props.part.part === 'review') return false
  if (props.part.meeting === 'watchtower' && props.part.part === 'mid-song') return false
  return true
})

const partCanHaveSecondSchool = computed(() => {
  if (props.part.meeting === 'treasures' && props.part.part === 'reading') return true
  if (props.part.meeting === 'ministry' && props.part.part === 'first' && !props.part.title.includes('Discussion')) return true
  if (props.part.meeting === 'ministry' && props.part.part === 'second') return true
  if (props.part.meeting === 'ministry' && props.part.part === 'third') return true
  if (props.part.meeting === 'ministry' && props.part.part === 'fourth') return true
  if (props.part.meeting === 'ministry' && props.part.part === 'study') return true
  if (props.part.meeting === 'ministry' && props.part.part === 'talk') return true
  return false
})

const partRequiresHelper = computed(() => {
  if (props.part.meeting === 'ministry' && props.part.time > 5) return false
  if (props.part.meeting === 'ministry' && props.part.references && props.part.references.includes('Talk.')) return false
  if (props.part.meeting === 'ministry' && props.part.title === 'Talk') return false
  if (props.part.meeting === 'ministry' && props.part.part === 'first' && !props.part.title.includes('Discussion')) return true
  if (props.part.meeting === 'ministry' && props.part.part === 'second') return true
  if (props.part.meeting === 'ministry' && props.part.part === 'third') return true
  if (props.part.meeting === 'ministry' && props.part.part === 'fourth') return true
  if (props.part.meeting === 'ministry' && props.part.part === 'study') return true
  if (props.part.meeting === 'living' && props.part.part === 'study') return true
  if (props.part.meeting === 'watchtower' && props.part.part === 'discussion') return true
  return false
})

const partCongregation = computed(() => {
  const ass = newStore.find('meetingAssignments', props.part.id, 'meeting_part_id')
  if (ass) return ass.speaker_congregation
  return null
})

const partTitle = computed(() => {
  if (props.chairman && props.hall === 1) return i18n.t('labels.meetings.chairman')
  else if (props.chairman && props.hall === 2) return i18n.t('labels.meetings.aux_counselor')
  else if (props.chairman && props.hall === 3) return i18n.t('labels.meetings.aux_counselor_2')
  else if (props.part.meeting === 'talk' && props.part.part === 'talk') {
    const ass = newStore.find('meetingAssignments', props.part.id, 'meeting_part_id')
    if (ass) return ass.notes
  }
  return props.part.title
})

const endTime = computed(() => {
  const seconds = (parseInt(props.part.time) * 60)
  return new Date(props.part.startTime.getTime() + (seconds * 1000))
})

const timeThroughPart = computed(() => {
  if (props.part.startTime && props.currentTime) {
    if (props.currentTime >
      props.part.startTime.getTime() &&
      props.currentTime <
      endTime.value.getTime()) {
      const percentageComplete = (props.currentTime - props.part.startTime.getTime()) / (endTime.value.getTime() - props.part.startTime.getTime()) * 100
      return (Math.round(percentageComplete * 100) / 100)
    }
  }
  return 0
})

const insertPart = (siblingPart) => {
  resource.value = { ...initialResource }
  resource.value.meeting = siblingPart.meeting
  resource.value.order = siblingPart.order + 1
  popupMenu.value = false
  editPartActive.value = true
}
const editPart = () => {
  resource.value = { ...initialResource }
  fillObject(resource.value, props.part)
  popupMenu.value = false
  editPartActive.value = true
}
const submitPart = () => {
  if (resource.value.id) {
    api.put(['meetings', props.part.meeting_id, 'parts', resource.value.id], resource.value)
      .then(res => {
        if (res.data.data.meeting_id !== props.part.meeting_id) {
          reloadSchedule()
        } else {
          newStore.merge('meetingParts', res, 'original_id')
          editPartActive.value = false
        }
      })
  } else {
    api.post(['meetings', props.part.meeting_id, 'parts'], resource.value)
      .then(res => {
        if (res.data.data.meeting_id !== props.part.meeting_id) {
          reloadSchedule()
        } else {
          newStore.merge('meetingParts', res, 'original_id')
        }
        editPartActive.value = false
      })
  }
}

const reloadSchedule = () => {
  profile.globalLoad = i18n.t('messages.building_schedule')
  newStore.get('meetings', { force: true }).then(() => {
    newStore.get('meetingParts', { force: true }).then(() => {
      newStore.get('meetingAssignments', { force: true }).then(() => {
        profile.globalLoad = false
        submitting.value = false
        editPartActive.value = false
      })
    })
  })
}

const destroyPart = () => {
  resource.value = { ...initialResource }
  fillObject(resource.value, props.part)
  popupMenu.value = false
  deletePartActive.value = true
}
const destruction = () => {
  api.delete(['meetings', props.part.meeting_id, 'parts', props.part.id])
    .then(() => {
      newStore.forget('meetingParts', props.part)
      deletePartActive.value = false
    })
}
const assign = (assignment, hall, type) => {
  if (props.part.meeting === 'talk' && props.part.part === 'talk') return
  emit('assign', props.part, hall || 1, assignment, type || 'main')
}

</script>
