<template>
  <div>
    <span v-if="address.unit && address.unit !== 0">Unit {{ address.unit }} / </span>
    <span>{{ address.house }}</span><!--
 --><span v-if="address.suffix && isNumber(address.suffix)">-{{ address.suffix }}</span><!--
 --><span v-else-if="address.suffix && isString(address.suffix)">{{ address.suffix }}</span>
    <div v-if="address.name">
      <small>{{ address.name }}</small>
    </div>
    <div v-if="address.data_1 && address.data_1 !== 'x'">
      <small>{{ $t('labels.address.number') }} {{ address.data_1 }}</small>
    </div>
    <div v-if="address.number && address.number !== 'x'">
      <small>{{ $t('labels.address.number') }} {{ address.number }}</small>
    </div>
    <div v-if="address.public_number && address.public_number !== 'x'">
      <small>{{ $t('labels.address.number') }} {{ address.public_number }}</small>
    </div>
    <div v-if="address.attempt">
      <small>
        {{ $t('labels.address.last_done', {
        day: day(address.adjusted_at),
        time: meridiem(address.adjusted_at),
        attempts: address.attempt
      }) }}
      </small>
    </div>
    <div v-if="address.lang">
      <small v-if="address.lang">{{ $t('labels.foreign.language',{language:$t('languages.'+ find(langs, ['id', address.lang]).iso) }) }}</small>
    </div>
    <div v-if="address.user">
      <small>{{ $t('labels.map.assigned', { 'name': pubName(address.user) }) }}</small>
    </div>

  </div>

</template>

<script setup>
import { useCollectionsStore } from '@/stores/collections'
import { computed } from 'vue'
import { store } from '@/store'
import { Sec } from '@/mixins/sec'
import { find, isString } from 'lodash'

defineProps(['address'])

const newStore = useCollectionsStore()

const langs = computed(() => store.state.core.langs)
const users = computed(() => newStore.all('users'))

const day = (time) => {
  return new Sec(time).format('D')
}
const meridiem = (time) => {
  return new Sec(time).format('A')
}
const isNumber = (n) => {
  const reg = new RegExp(/^\d+$/)
  return reg.test(n)
}

const pubName = (id) => {
  const pub = find(users.value, u => u.id === id)
  if (pub) return pub.full_name
  return null
}

</script>
