<template>
  <div>
      <label class="text-uppercase mb-0 font-semibold flex justify-between items-center">
        <div>{{ label }}</div>
          <input class="hidden" ref="options" type="checkbox" @change="checked($event.target.checked)" :name="slug(label)" :checked="value">
          <span class="relative inline-block p-px border border-gray-300 rounded-full" :class="value ? 'bg-teal-400' : 'bg-gray-100'" style="height:36px;min-width:70px">
            <span class="absolute block w-8 h-8 rounded-full m-0 p-3" :class="value ? 'right-px bg-gray-100' : 'bg-gray-300'">
            </span>
            <span class="absolute block w-9 text-center font-normal normal-case" :class="value ? 'text-white left-px' : 'right-px'" style="line-height:32px">
              {{ value ? ifTrue : ifFalse }}
            </span>
          </span>
      </label>
    <small class="text-grey-500" v-if="help">{{ help }}</small>
  </div>

</template>

<script setup>
import { slug } from '@/helpers'

defineProps({
  label: String,
  help: String,
  value: Boolean,
  ifTrue: {
    type: String,
    default: 'on'
  },
  ifFalse: {
    type: String,
    default: 'off'
  }
})
const emit = defineEmits(['input'])

const checked = (checked) => {
  emit('input', !!checked)
}
</script>

<style>

</style>
