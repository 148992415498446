<template>
  <div>
    <modal :header="'Field Service Report Form'" :active="active" @submit="submit" @close="emit('close')">
      <template v-slot:default="{ error }">
        <input-toggle class="mb-3"
          :label="'Did you share in any form of the ministry during the month?'"
          :name="'aux'"
          v-model="resource.active"
        />
        <input-toggle class="mb-3"
          v-if="canAuxPioneer"
          :label="'Did you Auxiliary Pioneer during the month?'"
          :name="'aux'"
          v-model="resource.aux"
        />
        <input-integer
          v-if="pioneering && resource.active"
          :label="'Number of full hours spent in the ministry'"
          :name="'hours'"
          v-model="resource.hours"
          :error="error"
        />
        <input-integer
          v-if="canHaveBibleStudies"
          :label="'Number of different Bible studies'"
          :name="'studies'"
          v-model="resource.studies"
          :error="error"
        />
        <input-integer
          v-if="canAddCreditHours"
          :label="'Hour Credits'"
          :name="'special_hours'"
          v-model="resource.special_hours"
          :error="error"
        />
        <input-message
          :label="'Comments'"
          :name="'comments'"
          v-model="resource.comments"
        />
      </template>
    </modal>

  </div>
</template>
<script setup>
import { ref, watch, computed } from 'vue'
import { post } from '@/composables/serverInterface.js'
import { fillObject } from '@/helpers'

const props = defineProps(['active', 'user'])
const emit = defineEmits(['close'])

const initialResource = {
  id: 0,
  user_id: null,
  report_month: null,
  active: false,
  hours: null,
  studies: null,
  special_hours: null,
  aux: null,
  pioneer: null,
  special_pioneer: null,
  comments: null
}

const resource = ref({ ...initialResource })
const pioneer = ref(false)
const specialPioneer = ref(false)

const pioneering = computed(() => {
  return pioneer.value || specialPioneer.value || resource.value.aux
})

const canAuxPioneer = computed(() => {
  return !pioneer.value && !specialPioneer.value && resource.value.active
})

const canHaveBibleStudies = computed(() => {
  return resource.value.active
})

const canAddCreditHours = computed(() => {
  return resource.value.active && (pioneer.value || specialPioneer.value)
})

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
    resource.value.user_id = props.user.id
    resource.value.pioneer = props.user.pioneer
    pioneer.value = props.user.pioneer
    resource.value.special_pioneer = props.user.special_pioneer
    specialPioneer.value = props.user.special_pioneer
    fillObject(resource.value, props.user.current_report)
  }
})

const submit = () => {
  post(['report'], resource.value).then(res => {
    emit('close', res)
  })
}

</script>
