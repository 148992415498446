<template>
  <div>
    <modal :header="'Change Outline for ' + speaker.full_name"
           :active="active"
           @submit="submit"
           @close="emit('close')"
           :validated="ptcValid"
    >
      <template v-slot:default="{ error }">
        <tuxedo-select
          name="public_talk_id"
          :label="'Talk'"
          v-model="resource.public_talk_id"
          :error="error"
          :options="sortedTalks"
          insistLabel="title"
          inputClass="bg-gray-100"
          insistReturn="id"
        >
          <template #label="{ option }">
            {{ option.number }}.&nbsp;<span v-html="option.title"></span>
          </template>
          <template #content="{ option }">
            <div v-if="option.promote" class="text-teal">{{ speaker.full_name }} gives this talk</div>
            <div>Last Date Given: {{ lastTimeTalkWasGiven(option) }}</div>
          </template>
        </tuxedo-select>

      </template>
    </modal>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { filter, find, orderBy, map, forEach } from 'lodash'
import { useCollectionsStore } from '@/stores/collections'
import { store } from '@/store'
import { Sec } from '@/mixins/sec'
import { post } from '@/composables/serverInterface.js'
import { merge } from '@/composables/dataInterface.js'

const props = defineProps(['congregation', 'speaker', 'publicTalk', 'active', 'defaultMeeting'])
const emit = defineEmits(['close'])

const newStore = useCollectionsStore()

const meetings = store.state.publicMeetings
const talks = store.state.publicTalkTitles
const ptcId = store.state.settings.cong.modules.meeting_schedule.ptc

const initialResource = {
  meeting_id: null,
  public_talk_id: null,
  assignment_id: null
}

const resource = ref({ ...initialResource })

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
    resource.value.meeting_id = props.publicTalk.meeting_id
    resource.value.speaker_id = props.speaker.id
    resource.value.assignment_id = props.publicTalk.assignment_id
    resource.value.public_talk_id = props.publicTalk.public_talk_id
  }
})

const ptc = computed(() => {
  return find(newStore.all('users'), p => parseInt(p.id) === parseInt(ptcId))
})

const ptcValid = computed(() => {
  return !!(ptc.value && ptc.value.email)
})

const speakerOutlines = computed(() => {
  return map(props.speaker.outlines, 'id')
})

const promotedTalks = computed(() => {
  return forEach(talks, function (t) { t.promote = speakerOutlines.value.includes(t.id) })
})

// eslint-disable-next-line no-unused-vars
const sortedTalks = computed(() => {
  return orderBy(promotedTalks.value, ['promote', 'number'], ['desc', 'asc'])
})

const lastTimeTalkWasGiven = (talk) => {
  const theNote = talk.number + '. ' + talk.title
  const meetingsWithTalk = filter(meetings.value, m => {
    const talkPart = find(m.parts, p => {
      return p.meeting === 'talk' && p.part === 'talk'
    })
    if (talkPart && talkPart.assignments && talkPart.assignments.length > 0) {
      return talkPart.assignments[0].notes === theNote
    }
  })
  if (meetingsWithTalk.length > 0) {
    return new Sec(orderBy(meetingsWithTalk, 'local_date')[0].local_date).format('jS F Y')
  }
}

const submit = () => {
  post(['change-speaker-outline'], resource.value)
    .then(res => {
      emit('saveResponse', res)
      merge('publicMeetings', res)
    })
}

</script>
