<template>
  <div id="app" class="bg-teal-darkest min-h-full w-full" v-if="languageLoaded">
    <app-down v-if="appDown" />
    <portal-target name="menus" />
    <portal-target name="modals" />
    <loader v-if="profile.appLoading"></loader>
    <div v-if="profile.globalLoad" class="fixed inset-0 z-20 bg-white flex justify-center items-center">
      <div class="font-sans font-bold bg-teal-100 p-3 rounded-md animate-pulse border border-teal">
        {{ profile.globalLoad }}
      </div>
    </div>
    <div v-if="!isOnline" class="offline-notice">{{ $t('labels.offline') }}</div>
    <navigation v-if="store.state.core.online && !singleView"></navigation>
    <v-tour name="tour" :steps="inviteTour.steps" :options="inviteTour.options"></v-tour>
    <tours></tours>
    <div v-if="device === 'android'" class="device-menu-location hidden position-fixed right-2 top"></div>
    <div v-else class="device-menu-location hidden position-fixed center bottom"></div>
    <div id="queue" class="fixed left-6 right-6 z-20 animate-queue-in" v-if="axiosQueue && axiosQueue.length > 0">
      <queue v-for="(next, key) in axiosQueue" :key="'queue' + key" :queue="next" />
    </div>
    <router-view class="w-full"
                 :error.sync="error"
                 v-if="store.state.core.online || route.path == '/auth/' || route.path == '/denied'">
    </router-view>
    <TempApp />
  </div>
</template>

<script setup>
import { computed, ref, watch, onBeforeMount, onMounted } from 'vue'
import { store } from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import { useProfileStore } from '@/stores/profile'

import navigation from './components/Navigation'
import TempApp from './TempApp.vue'
import loader from './components/general/loading-spinner.vue'
import { requestLanguage } from './i18n'

const route = useRoute()
const router = useRouter()
const profile = useProfileStore()

const languageLoaded = ref(false)
const error = ref(false)
const dataRefresh = ref(null)
const pollServer = ref(null)
const inviteTour = ref({
  options: {
    labels: {
      buttonStop: 'I Understand'
    }
  },
  steps: [
    {
      target: '.device-menu-location',
      content: '<strong>Action Required!</strong><br><br>You\'ve arrived here from a temporary link sent via SMS/Email, that link will expire soon.<br><br>It\'s recommended you save the Service App to your device, do so by accessing the browser menu and selecting \'Add to Home screen\', or alternatively save a bookmark.',
      params: {
        placement: 'bottom'
      }
    }
  ]
})

const isOnline = computed(() => store.state.core.isOnline)
const autoRefresh = computed(() => store.state.core.autoRefresh)
const axiosQueue = computed(() => store.state.tuxedo.axiosQueue)
const appDown = computed(() => store.state.core.appDown)
const singleView = computed(() => store.state.core.singleView)

const device = computed(() => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (/android/i.test(userAgent)) return 'android'
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'ios'
  return 'unknown'
})

onBeforeMount(() => {
  requestLanguage('en').then(() => {
    languageLoaded.value = true
  })
  // if (route.query.r) {
  //   console.log('Query R')
  //   store.state.core.temp_url = route.query.r
  // }
  // if (route.query.s) {
  //   console.log('Query S')
  //   store.state.core.singleView = route.query.r
  // }
  if (route.query.t) {
    store.state.core.token = route.query.t
    router.push('/auth/')
  }
  if (!store.state.online && route.path !== '/auth/') {
    if (route.query.t) {
      store.state.core.token = route.query.t
    }
    router.push('/auth/')
  }
})

onMounted(() => {
  clearInterval(pollServer.value)
  pollServer.value = setInterval(() => {
    if (document.hasFocus()) {
      console.log('Polling server')

      store.dispatch('POLL_SERVER')
    }
  }, 120 * 1000)
})

watch(() => autoRefresh, val => {
  if (val && !dataRefresh.value) {
    console.log('Auto Refresh Started!')
    dataRefresh.value = setInterval(() => {
      // TODO: Only need to refresh addresses rather than all assignments for speed and data
      store.dispatch('GET_ASSIGNMENTS')
      store.dispatch('GET_ADDRESS_OPTIONS')
    }, 60 * 1000)
  } else {
    console.log('Auto Refresh Cleared')
    clearInterval(dataRefresh.value)
  }
})

</script>
<style>
#app {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 72px;
}
</style>
