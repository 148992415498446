<template>
  <div class="public relative inset-x-0 top-0" v-if="startTime" :class="templateClass">

    <reset-schedule :meeting="meeting" />

    <meeting-part v-if="chairmanPart" :currentTime="currentTime" :part="chairmanPart" :chairman="true" @assign="assign" />

    <div v-if="talkParts.length">
      <div class="heading mb-3 shadow-md bg-talk text-white">{{ $t('schedules.public.public_talk') }} <span class="clock">{{ startTime | time }}</span></div>
      <meeting-part v-for="part in talkParts" :key="'part' + part.id" :currentTime="currentTime" :part="part" :edit="edit" />
    </div>

    <div v-if="watchtowerParts.length">
      <div class="heading mb-3 shadow-md bg-watchtower text-white">{{ $t('schedules.public.watchtower') }} <span class="clock">{{ watchtowerStart | time }}</span></div>
      <meeting-part v-for="part in watchtowerParts" :key="'part' + part.id" :currentTime="currentTime" :part="part" :edit="edit" :defaultTag="part.part === 'last_song' ? null : null" @assign="assign" />
    </div>

    <attendance-form class="mb-24" v-if="cong.modules.meeting_attendance.on && profile.can('settings.meetings.attendance')" :attendance="attendance" :meeting="meeting" />

    <meeting-assignment
      :part="part"
      :hall="assignmentHall"
      :assignment="assignment"
      :assignmentType="assignmentType"
      :active.sync="assignmentFormActive" />

  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { store } from '@/store'
import { filter, find, orderBy } from 'lodash'
import { useCollectionsStore } from '@/stores/collections'
import { useProfileStore } from '@/stores/profile'
import { Sec } from '@/mixins/sec'
import ResetSchedule from './ResetSchedule'

const props = defineProps(['activeMeeting', 'edit', 'active', 'meeting', 'day'])

const newStore = useCollectionsStore()
const profile = useProfileStore()

const loadDirection = ref('forward')
const part = ref({})
const assignmentHall = ref(1)
const assignment = ref({})
const assignmentType = ref('main')
const assignmentFormActive = ref(false)
const timerInterval = ref(null)
const currentTime = ref(null)

onMounted(() => {
  currentTime.value = new Sec().time
  timerInterval.value = setInterval(() => {
    currentTime.value = new Sec().time
  }, 1000)
})

onUnmounted(() => {
  if (timerInterval.value) clearInterval(timerInterval.value)
})

const cong = computed(() => store.state.settings.cong)
const attendance = computed(() => {
  const a = newStore.find('meetingAttendance', props.meeting.id, 'meeting_id')
  return a?.attendance || 0
})

const templateClass = computed(() => {
  if (props.activeMeeting === 'clam') return 'animate-forward-out'
  else if (startTime.value) return 'animate-' + loadDirection.value + '-in'
  else return 'hidden'
})

const parts = computed(() => {
  return orderBy(filter(newStore.all('meetingParts'), p => p.meeting_id === props.meeting.edited_id ?? props.meeting.id), ['order', 'asc'])
})

const startTime = computed(() => {
  const date = new Date(props.meeting.start_date)
  const currentDay = date.getDay()
  let publicDay = cong.value.modules.meeting_schedule.public_day || 0
  publicDay = parseInt(publicDay) === 0 ? 7 : publicDay
  const distance = parseInt(publicDay) - currentDay
  date.setDate(date.getDate() + distance)
  const time = cong.value.modules.meeting_schedule.public_start_time.split(':')
  date.setHours(time[0], time[1], 0)
  return date
})

const watchtowerStart = computed(() => {
  let minutes = 0
  talkParts.value.forEach(p => { minutes += parseInt(p.time) })
  return new Date(startTime.value.getTime() + (minutes * 60000))
})

const chairmanPart = computed(() => {
  return find(parts.value, p => { return p.meeting === 'talk' && p.part === 'song' })
})

const talkParts = computed(() => {
  const arr = filter(parts.value, r => r.meeting === 'talk')
  let minutes = 0
  arr.forEach(p => {
    p.startTime = new Date(startTime.value.getTime() + (minutes * 60000))
    minutes += parseInt(p.time)
  })
  return arr
})

const watchtowerParts = computed(() => {
  const arr = filter(parts.value, r => r.meeting === 'watchtower')
  let minutes = 0
  arr.forEach(p => {
    p.startTime = new Date(watchtowerStart.value.getTime() + (minutes * 60000))
    minutes += parseInt(p.time)
  })
  return arr
})

watch(() => props.day, (newVal, oldVal) => {
  if (props.activeMeeting === 'public') {
    if (parseInt(newVal) > parseInt(oldVal)) loadDirection.value = 'backward'
    else loadDirection.value = 'forward'
  }
})

watch(() => props.activeMeeting, (newVal, oldVal) => {
  if (oldVal === 'clam') loadDirection.value = 'backward'
  else loadDirection.value = 'forward'
})

const assign = (p, hall, a, type) => {
  part.value = p
  assignment.value = a
  assignmentType.value = type || 'main'
  assignmentHall.value = hall || 1
  assignmentFormActive.value = true
}

</script>
