<template>
  <div>
    <modal :header="'Add Publisher'" :active="active" @submit="submit" @close="emit('close')">
      <template v-slot:default="{ error }">
        <tuxedo-select
          :inputClass="'bg-gray-100'"
          :label="'Publishers'"
          :name="'users'"
          v-model="resource.users"
          :options="optionalPubs"
          :insistLabel="'last_first'"
          :insistReturn="'id'"
          :error="error"
          :multi="true"
        />
      </template>
    </modal>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { useAPIStore } from '@/stores/api'
import { filter, findIndex, orderBy } from 'lodash'

const props = defineProps(['active', 'part'])
const emit = defineEmits(['close'])

const store = useCollectionsStore()
const api = useAPIStore()

const initialResource = {
  users: []
}

const resource = ref({ ...initialResource })

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource, id: props.part }
  }
})

const optionalPubs = computed(() => {
  let pubs = orderBy(store.all('users'), ['last_first'])
  pubs = filter(pubs, pub => {
    if (parseInt(pub.active) === 0) return false
    // Parts requiring a brother (not cleared out by the following rules)
    if (
      [1, 5, 9, 12, 13].indexOf(parseInt(props.part)) >= 0 &&
      parseInt(pub.gender) === 2
    ) return false
    // Parts requiring an MS or Elder
    if (
      [2, 3, 4, 10, 11, 14, 15, 16, 17].indexOf(parseInt(props.part)) >= 0 &&
      (!['elder', 'servant'].includes(pub.appointment))
    ) return false
    // Parts requiring a baptised publisher
    if (
      [1, 12, 13].indexOf(parseInt(props.part)) >= 0 &&
      (!['elder', 'servant', 'publisher'].includes(pub.appointment))
    ) return false
    // Parts which can be handled by an unbaptised publisher
    if (
      [7, 8, 9].indexOf(parseInt(props.part)) >= 0 &&
      (!['elder', 'servant', 'publisher', 'unbaptised', 'student'].includes(pub.appointment))
    ) return false
    // Parts which can be handled by a student
    // if ([5, 6].indexOf(this.part.id) >= 0) return false
    if (
      findIndex(pub.qualified_parts, p => parseInt(p.id) === parseInt(props.part)) >= 0
    ) return false
    return true
  })
  return pubs
})

const submit = () => {
  api.post(['user-part-qualification'], resource.value).then(res => {
    store.merge('users', res)
    emit('close')
  })
}

</script>
