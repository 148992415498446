<template>
  <div>
    <alert-box v-if="editedSchedule && profile.can('schedules.meetings.edit')">
      <div class="flex justify-content-between align-items-center">
        <div>{{ i18n.t('messages.edited_schedule') }}</div>
        <action :secondary="true"
                v-if="profile.can('schedules.meetings.import')"
                :small="true"
                @click="resetMeetingScheduleActive = true">
          {{ label }}
        </action>
      </div>
    </alert-box>
    <modal :header="label"
           :submitting="submitting"
           :active.sync="resetMeetingScheduleActive"
           :save="i18n.t('actions.reset')"
           @submit="submit">
      <p>{{ i18n.t('prompts.meeting.reset_schedule') }}</p>
    </modal>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useProfileStore } from '@/stores/profile'
import { useCollectionsStore } from '@/stores/collections'
import { useAPIStore } from '@/stores/api'
import { i18n } from '@/i18n'

const props = defineProps(['meeting'])

const profile = useProfileStore()
const store = useCollectionsStore()
const api = useAPIStore()

const submitting = ref(false)
const resetMeetingScheduleActive = ref(false)

const label = computed(() => {
  return i18n.t('actions.action_resource', { action: i18n.t('actions.reset'), resource: i18n.tc('resources.schedule', 1) })
})

const editedSchedule = computed(() => {
  return props.meeting.id !== props.meeting.edited_id
})

const submit = () => {
  submitting.value = false
  resetMeetingScheduleActive.value = false
  profile.globalLoad = i18n.t('messages.building_schedule')
  api.get(['meeting-schedule', props.meeting.id, 'reset']).then(() => {
    store.get('meetings', { force: true }).then(() => {
      store.get('meetingParts', { force: true }).then(() => {
        store.get('meetingAssignments', { force: true }).then(() => {
          profile.globalLoad = false
        })
      })
    })
  })
}

</script>
