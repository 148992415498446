<template>
  <modal :submitting="submitting" :header="'Congregation Details'" :active="active" @close="emit('close')" @submit="submit" :validated="valid">
    <input-text :label="'Name'" :type="'text'" v-model="cong.name" />
    <tuxedo-select
      :label="'Country'"
      name="country_id"
      :options="countries"
      v-model="cong.country_id"
      insistLabel="name"
      inputClass="bg-gray-100"
      insistReturn="id"
    />
    <tuxedo-select
      :label="'Region'"
      name="region_id"
      :options="filteredRegions"
      v-model="cong.region_id"
      insistLabel="name"
      inputClass="bg-gray-100"
      insistReturn="id"
    />
    <input-text :label="'Suburb'" :type="'text'" v-model="cong.suburb" />
    <input-text :label="'Zip/Post Code'" :type="'text'" v-model="cong.postcode" />
    <input-text :label="'Address'" :type="'text'" v-model="cong.address" />
    <tuxedo-select
      :label="'Language'"
      name="lang"
      :options="langs"
      v-model="cong.lang"
      insistLabel="lang"
      inputClass="bg-gray-100"
      insistReturn="iso"
    />
    <tuxedo-select
      :label="'Timezone'"
      name="timezone_id"
      :options="filteredTimezones"
      v-model="cong.timezone_id"
      insistLabel="name"
      inputClass="bg-gray-100"
      insistReturn="id"
    />
    <tuxedo-select
      :label="'Time Format'"
      :help="'Indicate if your congregation prefers time displayed in 12 or 24 hour formats.'"
      name="time_format"
      :options="[{id:12,name:'12 Hour Format'},{id:24,name:'24 Hour Format'}]"
      v-model="cong.time_format"
      insistLabel="name"
      inputClass="bg-gray-100"
      insistReturn="id"
    />
    <input-text :label="'Circuit'" :type="'text'" v-model="cong.circuit" />
    <tuxedo-select
      :label="'Accounts Servant'"
      name="accounts_pub"
      :options="publishersWithEmail"
      v-model="cong.accounts_pub"
      insistLabel="last_first"
      inputClass="bg-gray-100"
      insistReturn="id"
    />

    <input-integer :label="'Maximum Assigned Maps'" v-model="cong.assigned_map_cutoff" :help="'The total number of maps that can be assigned out at once, if this is reached publishers will be advised to Request Addresses as there are no more maps available.'" />
    <input-integer :label="'Stagnant Map Threshold'" v-model="cong.stagnant_map_threshold" :help="'The number of days a publisher can have a map assigned to them without any activity. When this threshold is reached the map is considered stagnant and the publisher will be sent a message encouraging them to hand the map back in for someone else to work.'" />
    <input-toggle class="mb-3" :label="'Display Blocks as Letters'" v-model="cong.blocks_as_letters" :help="'If your congregation uses letters to identify blocks, rather than numbers, you can toggle this option to on and display map blocks as letters (eg. A, B, C, etc.) instead of numerical (eg. 1, 2, 3, etc.)'"></input-toggle>
    <input-integer :label="'Options Shown when Requesting Addresses'" v-model="cong.address_option_limit" :help="'Set the number of options to display when publishers request addresses, these are always sorted in chronological order, with the oldest being displayed first.'" />
    <input-integer :label="'Address Attempts'" v-model="cong.address_attempts" :help="'Set the amount of unsuccessful attempts on an address before it expires, this number includes the initial visit.'" />
    <input-integer :label="'Address Timeout'" v-model="cong.address_timeout" :help="'Set the number of days before an address expires. If an address reaches this limit it will expire regardless of how many times it\'s been attempted.'" />
    <tuxedo-select
      :label="'Outcome for expired addresses'"
      name="expired_addresses"
      :options="addressOptions"
      v-model="cong.expired_addresses"
      inputClass="bg-gray-100"
      insistReturn="id"
    />
    <tuxedo-select
      v-if="cong.expired_addresses > 0"
      :label="'Email addresses to'"
      name="email_addresses_to"
      :options="publishersWithEmail"
      v-model="cong.email_addresses_to"
      insistLabel="last_first"
      inputClass="bg-gray-100"
      insistReturn="id"
    />
    <input-toggle class="mb-3" :label="'Allow Publishers to Book Carts'" v-model="cartCalendar.cart_booking" :help="'Allow publishers to book carts and edit bookings. If off, only Admin roles will be able to create and edit bookings.'" />
    <input-integer :label="'Open Booking Weeks'" v-model="cartCalendar.booking_advance" :help="'Set the amount of weeks ahead of the current date that publishers can book carts (This rule will not be applied to Admin roles). Set to 0 if you want carts to be booked ahead infinitely.'" />
    <input-toggle :label="'Handle Field Service Reporting'" v-model="fieldServiceReports.on" />
  </modal>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { post } from '@/composables/serverInterface'
import { assign } from '@/composables/dataInterface'
import { store } from '@/store'
import { filter, orderBy } from 'lodash'

const newStore = useCollectionsStore()

const props = defineProps(['active'])
const emit = defineEmits(['close'])

const addressOptions = [
  { id: 0, label: 'Delete' },
  { id: 1, label: 'Email' }
]

const settings = computed(() => store.state.settings)
const users = computed(() => newStore.all('users'))
const countries = computed(() => newStore.all('countries'))
const regions = computed(() => newStore.all('regions'))
const timezones = computed(() => store.state.timezones)
const langs = computed(() => store.state.core.langs)

const filteredRegions = computed(() => {
  if (cong.value.country_id) {
    return filter(regions.value, r => r.country_id === parseInt(cong.value.country_id))
  }
  return regions.value
})

const filteredTimezones = computed(() => {
  if (cong.value.country_id && timezones.value) {
    return filter(timezones.value, z => z.countries.includes(parseInt(cong.value.country_id)))
  }
  return timezones.value
})

const publishersWithEmail = computed(() => {
  let pubs = []
  if (users.value) {
    pubs = users.value.filter(pub => pub.email && pub.active)
  }
  return orderBy(pubs, ['last_first'])
})

const valid = computed(() => {
  if (cong.value.name) {
    return true
  } else { return false }
})

const submitting = ref(false)
const cong = ref({
  name: null,
  country_id: null,
  region_id: null,
  suburb: null,
  postcode: null,
  address: null,
  lang: null,
  time_format: null,
  timezone_id: null,
  circuit: null,
  accounts_pub: null,
  assigned_map_cutoff: null,
  stagnant_map_threshold: null,
  blocks_as_letters: null,
  address_option_limit: null,
  address_attempts: null,
  address_timeout: null,
  expired_addresses: null,
  email_addresses_to: null
})
const cartCalendar = ref({
  cart_booking: false,
  booking_advance: null
})
const fieldServiceReports = ref({
  on: false
})

watch(() => props.active, async (active) => {
  if (active) {
    Object.keys(cong.value).forEach(key => {
      cong.value[key] = settings.value.cong[key]
    })
    let module = settings.value.cong.modules.field_service_reports
    Object.keys(fieldServiceReports.value).forEach(key => {
      if (module[key]) fieldServiceReports.value[key] = (key === 'on' ? Boolean(module[key]) : module[key])
    })
    module = settings.value.cong.modules.cart_calendar
    Object.keys(cartCalendar.value).forEach(key => {
      if (module[key]) cartCalendar.value[key] = (key === 'cart_booking' ? Boolean(module[key]) : module[key])
    })
  }
})

const submit = () => {
  submitting.value = true
  post(['congregation'], {
    ...cong.value,
    modules: {
      field_service_reports: fieldServiceReports.value,
      cart_calendar: cartCalendar.value
    }
  })
    .then(res => {
      const obj = { ...settings.value }
      obj.cong = res.data.data
      assign('settings', obj)
      submitting.value = false
      emit('close')
    })
    .catch(() => {
      submitting.value = false
    })
}

</script>
